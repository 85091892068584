import Swank from "../img/channel_logo_white/Swank_logo.png";
import TowieLogo from "../img/partner_logo_white/TOWIE_logo.png";
import Whistlesports from "../img/partner_logo_white/whistlesports_logo.png";
// knandi started
import AlliedVaughnleLogo from "../img/channel_logo_white/AlliedVaughn_logo.png";
import AtmosphereLogo from "../img/channel_logo_white/atmosphere_logo.png";
import ChristianCinema from "../img/channel_color_logo/christianCinema_logo.png";
import PaperCupCmn from "../img/partner_logo_white/papercup_logo.png";
import DishEst from "../img/partner_logo_white/dish-EST_logo.png";
import SpherexCmn from "../img/partner_logo_white/Spherex_logo.png";
import DishNet from "../img/partner_logo_white/DishNetwork_logo.png";
import DistributionSolutions from "../img/partner_logo_white/distribution_solutions_logo.png";
import GoogleYoutube from "../img/channel_logo_white/GoogleYoutube_logo.png";
import IMdb from "../img/channel_logo_white/IMDB_logo.png";
import InDemand from "../img/channel_logo_white/inDemand_logo.png";
import InDemandEst from "../img/channel_logo_white/inDemandESt_logo.png";
import InApp from "../img/channel_logo_white/innapp_logo.png";
import ITunes from "../img/partner_logo_white/itunes_logo.png";
// import Junction from "../img/channel_logo_white/junctionTV_logo.png";
import OverDrive from "../img/channel_logo_white/OverDrive_logo.png";
import FlixFling from "../img/channel_logo_white/Flixfling_logo.png";
import PopcornFlix from "../img/channel_logo_white/PopcornFlix_logo.png";
import RedboxDemand from "../img/channel_logo_white/redBox_ondemand_logo.png";
import Rebox from "../img/channel_logo_white/rebox_logo.png";
import SamsungTV from "../img/Partner_2_white_logo/sumsungTv_logo.png";
import SamsungTvMobile from "../img/Partner_2_white_logo/sumsungTMobile_logo.png";
import SamsungTVplus from "../img/Partner_2_white_logo/sumsungTv_logo_plus.png";
import Vsc from "../img/channel_logo_white/vsc_logo.png";
import Vubiquity from "../img/channel_logo_white/Vubiquity_logo.png";
import VubiquityAvod from "../img/channel_logo_white/VubiquityAVOD_logo.png";
import WatchFree from "../img/Partner_2_white_logo/watchFree_logo.png";
import Trc from "../img/channel_logo_white/Trc_logo.png";
import Canela from "../img/Partner_2_white_logo/canela_logo.png";

//Channel logo
import AsiancrushLogo from "../img/channel_color_logo/AsianCrush_logo.png";
import BambooLogo from "../img/channel_color_logo/bamboo_logo.png";
import BloodyDisgustingLogo from "../img/channel_color_logo/Bloody-disgusting_logo.png";
import BobrossLogo from "../img/channel_color_logo/bob_ross_logo.png";
import BobRossEnEspanoLogo from "../img/channel_color_logo/BobRossEnEspano_logo.png";
import ContvLogo from "../img/channel_color_logo/contv_logo.png";
import ContvAnimLogo from "../img/channel_color_logo/contvanim_logo.png";
import CineDigm from "../img/channel_color_logo/cinedigm_logo.png";
import cineverse from "../img/channel_color_logo/cineverse_logo.png";
import CineverseHubLogo  from "../img/channel_color_logo/cineverse_hub_logo.png";
import CocoroLogo from "../img/channel_color_logo/Cocoro_logo.png";
import CombatgoLogo from "../img/channel_color_logo/combatgo_logo.png";
import ComedydyanamicsLogo from "../img/channel_color_logo/comedy_dynamics_logo.png";
import ComedydynamicsClean from "../img/channel_color_logo/comedy_dynamics_clean_logo.png";
import crimehuntersLogo from "../img/channel_color_logo/CrimeHunters_logo.png";
import DocuramaLogo from "../img/channel_color_logo/Docurama_logo.png";
import DoveLogo from "../img/channel_color_logo/dove_logo.png";
import ElReyLogo from "../img/channel_color_logo/ElRey_logo.png";
import Fandor from "../img/channel_color_logo/fandor_logo.png";
import FantawildLogo from "../img/channel_color_logo/Fantawild_logo.png";
import FasionboxLogo from "../img/channel_color_logo/fasionbox_logo.png";
import TheFilmDetectiveLogo from "../img/channel_color_logo/thefilmdetective_logo.png";
import GametoonLogo from "../img/channel_color_logo/gametoon_logo.png";
import HallyPopLogo from "../img/channel_color_logo/hallypop_logo.png";
import HappiTVLogo from "../img/channel_color_logo/HappiTV_logo.png";
import KMTVLogo from "../img/channel_color_logo/KMTV_logo.png";
import KorTVLogo from "../img/channel_color_logo/KorTV_logo.png";
import KlowdTV from "../img/platform_color_logo/Klowd_logo.png";
import LoneStarLogo from "../img/channel_color_logo/Lone_Star_logo.png";
import MidnightPulpLogo from "../img/channel_color_logo/MidnightPulp_logo.png";
import MytimeLogo from "../img/channel_color_logo/mytime_logo.png";
import NewVideo from "../img/channel_color_logo/new_video_logo.png";
import PlayStation from "../img/channel_color_logo/Playstation_logo.png";
import qttv from "../img/channel_color_logo/QTTV_logo.png";
import RealMadridLogo from "../img/channel_color_logo/realmadrid_logo.png";
import RetrocrushLogo from "../img/channel_color_logo/retroCrush_logo.png";
import Sanctuary from "../img/channel_color_logo/sanctuary_logo.png";
import Screambox from "../img/channel_color_logo/screamBox_logo.png";
import SoDramatic from "../img/channel_color_logo/so_dramatic_logo.png";
import SorealLogo from "../img/channel_color_logo/soreal_logo.png";
import TCN from "../img/channel_color_logo/TheCountryNetwork_logo.png";
import TheEvispresleyChannel from "../img/channel_color_logo/TheElvisPresley_logo.png";
import TheOnlyWayIsEssexLogo from "../img/channel_color_logo/theOnlyWayIsEssex_logo.png";
import Viewster from "../img/channel_color_logo/viewstar_logo.png";
import WhistleLogo from "../img/channel_color_logo/whistle_logo.png";
import gustotvLogo from "../img/channel_color_logo/Gusto_logo.png";
import qwestLogo from "../img/channel_color_logo/qwest_logo.png";
import MaverickLogo  from "../img/channel_color_logo/Maverick_logo.png";
import TwistedMirrorLogo  from "../img/channel_color_logo/Twisted_mirror_logo.png";

//Platform logo
import AfricaChannelLogo from "../img/platform_color_logo/AfricaChannel_logo.png";
import AmazonLogo from "../img/platform_color_logo/amazon-logo.png";
import AmazonEstVODLogo from "../img/platform_color_logo/amazon-est-vod-logo.png";
import AmazonPrimeVideoLogo from "../img/platform_color_logo/amazon-prime-video_logo.png";
import AndroidLogo from "../img/platform_color_logo/android_logo.png";
import AuxModeLogo from "../img/platform_color_logo/auxmode_logo.png";
import BingeBlackTVLogo from "../img/platform_color_logo/bingeblack_logo.png";
import CanelaTVLogo from "../img/platform_color_logo/CanelaTV_logo.png";
import ComcastLogo from "../img/platform_color_logo/com-cast_logo.png";
import CrackleLogo from "../img/platform_color_logo/Crackle_logo.png";
import DarkmatterTVLogo from "../img/platform_color_logo/DarkmatterTV_logo.png";
import DistroLogo from "../img/platform_color_logo/Distro_logo.png";
import FandangoNowLogo from "../img/platform_color_logo/FandangoNow_logo.png";
import FrndlyLogo from "../img/platform_color_logo/FRNDLY_logo.png";
import FuboTVLogo from "../img/platform_color_logo/FuboTV_logo.png";
import FuseLogo from "../img/platform_color_logo/fuse_logo.png";
import FutureTodayLogo from "../img/platform_color_logo/Future_Today_logo.png";
import GCILogo from "../img/platform_color_logo/GCI_logo.png";
import GumGum from "../img/platform_color_logo/gumgum_logo.png";
import KanopyLogo from "../img/platform_color_logo/Kanopy_logo.png";
import KartoonChannelLogo from "../img/platform_color_logo/kartoon_channel_logo.png";
import Klowd from "../img/platform_color_logo/Klowd_logo.png";
import LGLogo from "../img/platform_color_logo/LG_logo.png";
import LittlStar from "../img/platform_color_logo/Littlestar_logo.png";
import LocalNow from "../img/platform_color_logo/Local_Now_logo.png";
import MatchpointLogo from "../img/platform_color_logo/matchpoint_logo.png";
import MXPlayerLogo from "../img/platform_color_logo/MX_Player_Logo.png";
import NrealLogo from "../img/platform_color_logo/nreal_logo.png";
import ONOLogo from "../img/platform_color_logo/ono_logo.png";
import otteraLogo from "../img/platform_color_logo/Ottera_logo.png";
import Peacock from "../img/platform_color_logo/peacocktv.png";
import Plex from "../img/platform_color_logo/plex_logo.png";
import Pluto from "../img/platform_color_logo/plutotv_logo.png";
import Rakuten from "../img/platform_color_logo/Rakuten_logo.png";
import Redbox from "../img/platform_color_logo/redBox_logo.png";
import RlaxxTVLogo from "../img/platform_color_logo/RlaxxTV_logo.png";
import RockbotLogo from "../img/platform_color_logo/Rockbot_logo.png";
import Roku from "../img/platform_color_logo/roku_logo.png";
import RokuNewManLogo from "../img/platform_color_logo/roku_new_man_logo.png";
import SBSLogo from "../img/platform_color_logo/SBS_logo.png";
import SLMediaLogo from "../img/platform_color_logo/SLmedia.png";
import SlingLogo from "../img/platform_color_logo/sling_logo.png";
import STIRRLogo from "../img/platform_color_logo/STIRR_logo.png";
import SamsungLogo from "../img/platform_color_logo/sumsung_logo.png";
import SelectTV from "../img/platform_color_logo/selectTv_logo.png";
import SidestreamLogo from "../img/platform_color_logo/sidestream-logo.png";
import Stremium from "../img/platform_color_logo/stremium_logo.png";
import TCL from "../img/platform_color_logo/TCL-logo.png";
import ToonGooglesLogo from "../img/platform_color_logo/Toon-Goggles-logo.png";
import TubiLogo from "../img/platform_color_logo/tubitv_logo.png";
import TwitchLogo from "../img/platform_color_logo/twich_logo.png";
import ToTheNewLogo from "../img/platform_color_logo/tothenew_logo.png";
import TVPassLogo from "../img/platform_color_logo/TVpass_Logo.png";
import utubteTVLogo from "../img/platform_color_logo/YoutubeTV_logo.png";
import UPTVLogo from "../img/platform_color_logo/upvt_logo.png";
import UCastLogo from "../img/platform_color_logo/u-cast_logo.png";
import VAMediaLogo from "../img/platform_color_logo/vma_logo.png";
import Vewd from "../img/platform_color_logo/vewd_logo.png";
import VimeoLogo from "../img/platform_color_logo/vimeo-logo.png";
import Vizio from "../img/platform_color_logo/vizio_logo.png";
import VuduLogo from "../img/platform_color_logo/vudu_logo.png";
import WeWatchLogo from "../img/platform_color_logo/WeWatch_logo.png";
import XBOXLogo from "../img/platform_color_logo/xbox-logo.png";
import XiaomiLogo from "../img/platform_color_logo/xiaomi_logo.png"
import XumoLogo from "../img/platform_color_logo/xumo_logo.png";
import ZypeLogo from "../img/platform_color_logo/Zype_logo.png";
import YuyuLogo from "../img/platform_color_logo/Yuyu_logo.png";
import philoLogo from "../img/platform_color_logo/philo_logo.png";
import scrippsLogo from "../img/platform_color_logo/scripps_logo.png";

//digital partner
import AppleLogo from "../img/digital_partner_color_logo/apple_logo.png";
import Hoopla from "../img/channel_color_logo/Hoopla_logo.png";
import GoogleLogo from "../img/digital_partner_color_logo/google_logo.png";
import MicrosoftLogo from "../img/digital_partner_color_logo/microsoft_logo.png";
import RentrakLogo from "../img/digital_partner_color_logo/rentrak_logo.png";
import SteamLogo from "../img/digital_partner_color_logo/steam_logo.png";
import Row8Logo from "../img/digital_partner_color_logo/Row8_logo.png";

// OTT Partner
import AmagiLogo from "../img/OTT_partner_color_logo/amagi_logo.png";
import coxLogo from "../img/OTT_partner_color_logo/Cox_Logo.png";
import dishLogo from "../img/OTT_partner_color_logo/dish_logo.png";

// Device Type
import AndroidtvLogo from "../img/devicetype_color_logo/androidtv_logo.png";
import Html5Logo from "../img/devicetype_color_logo/html5_logo.png";
import IosLogo from "../img/devicetype_color_logo/ios_logo.png";
import TvosvLogo from "../img/devicetype_color_logo/tvos_logo.png";
import WebLogo from "../img/devicetype_color_logo/web_logo.png";

let cdate = new Date();
//let pre_month = cdate.setMonth(cdate.getMonth() - 1);

let pre_month = new Date(cdate.getFullYear(), cdate.getMonth() - 1, 1);
let toDate = new Date(cdate.getFullYear(), cdate.getMonth() - 1, 1);
let fromDate = new Date(cdate.getFullYear()-1, cdate.getMonth() - 1, 1);
let defaultToDate = new Date(cdate.getFullYear(), cdate.getMonth() - 1, 1);
let defaultFromDate = new Date(cdate.getFullYear()-1, cdate.getMonth() - 1, 1);
let quarter = "Q"+Math.floor((cdate.getMonth() + 3) / 3);
let year = cdate.getFullYear();

let dailyToDate = new Date(cdate.setDate(cdate.getDate()-1));
let dailyFromDate = new Date(dailyToDate.getFullYear(), dailyToDate.getMonth() - 1, dailyToDate.getDate());

let dailyToWeek = new Date(dailyToDate.getFullYear(), dailyToDate.getMonth(), (dailyToDate.getDate()-7) - (dailyToDate.getDay()) + 1);
let dailyFromWeek = new Date(dailyToDate.getFullYear(), dailyToDate.getMonth()-3, (dailyToDate.getDate()-13) - (dailyToDate.getDay()));

let dailyToQuarter = new Date(cdate.setDate(cdate.getDate()-1));
let dailyFromQuarter = new Date(cdate.setDate(cdate.getDate()-1));
    dailyFromQuarter = new Date(dailyFromQuarter.setFullYear(dailyFromQuarter.getFullYear()-1));

let dailyToYear = new Date(cdate.getFullYear(), "11", "31");
let dailyFromYear = new Date(cdate.getFullYear()-1, "00","01");

// --------------- partnership dashboard Top Performer ---------------

let TopPerformerRefYear  = new Date(cdate.getFullYear()-1, "00","01");
let TopPerformerMainYear = new Date(cdate.getFullYear(), "00", "01");

let TopPerformerMainQuarter = new Date(cdate.setDate(cdate.getDate()-1));
let TopPerformerRefQuarter  = new Date(cdate.setDate(cdate.getDate()-1));
    TopPerformerRefQuarter  = new Date(TopPerformerRefQuarter.setFullYear(TopPerformerRefQuarter.getFullYear()-1));

let TopPerformerMainMonth = new Date(cdate.getFullYear(), cdate.getMonth() - 1, 1);
let TopPerformerRefMonth  = new Date(cdate.getFullYear()-1, cdate.getMonth() - 1, 1);

const versionInfo = require("../js/version.json");
let pmode = versionInfo.pmode;

export const ConVar = {
  authCookieName: "_matchpoint",
  service: "insights",
  superAdmin: (pmode === "live") ? [
    "sghorui@cineverse.com",
    "mthiele@cineverse.com",
    "adutta@cineverse.com",
    "ksarkar@cineverse.com",
    "mbasak@cineverse.com"
  ] : [
    "sghorui@cineverse.com",
    "mthiele@cineverse.com",
    "adutta@cineverse.com",
    "mbasak@cineverse.com",
    "kkamran@cineverse.com",
    "ksarkar@cineverse.com",
    "smallick@cineverse.com",
    "sbiswas@cineverse.com",
    "droy@cineverse.com"
  ],
  custname: "JunctionTV",
  custid: "cinedigm",
  authCookieDomain: "insights.matchpoint.tv",
  clientLogo:
    "https://cdn.matchpoint.tv/glah2jxadjdhioanlex9spy6ebqlumearmwgncpuzpvi26h271ns7azli8r65a8mekcm8b5nxib229fnb59pjzi5exz3ppunhn3q/matchpoint_insight_logo_black.svg",

  globalFilter: {
    monthYear: pre_month,
    prevMonth: pre_month,
    year:year,
    quarter:quarter,
    fromDate:fromDate,
    toDate:toDate,
    defaultFromDate:defaultFromDate,
    defaultToDate:defaultToDate,
    dailyToDate:dailyToDate,
    dailyFromDate:dailyFromDate,
    dailyToWeek:dailyToWeek,
    dailyFromWeek:dailyFromWeek,
    dailyToQuarter:dailyToQuarter,
    dailyFromQuarter:dailyFromQuarter,
    dailyToYear:dailyToYear,
    dailyFromYear:dailyFromYear,
    TopPerformerMainYear:TopPerformerMainYear,
    TopPerformerRefYear:TopPerformerRefYear,
    TopPerformerMainQuarter:TopPerformerMainQuarter,
    TopPerformerRefQuarter:TopPerformerRefQuarter,
    TopPerformerMainMonth:TopPerformerMainMonth,
    TopPerformerRefMonth:TopPerformerRefMonth,
  },
  defaultMsg: {
    tablemsg: "No data found",
  },
  scheduleReportTxt:
    "Schedule future reports automatically generated at periodic intervals based on the selected parameters",
  generateReportTxt:
    "Generate historical report sorted on the selected parameters",

  withoutDecimal: [
    "ad_impressions",
    "ad_unique_users",
    "ad_bid_wons",
    "ad_bid_auctions",
    "ad_pod_slot_requests",
    "ad_pod_slots_returned",
    "ad_pod_time_requested",
    "ad_pod_time_filled",
    "ad_pod_time_returned",
    "ad_total_requests",
    "ad_opportunities",
    "views",
    "users",
    "viewtime",
    "transactions",
    "sub_cancelled_users",
    "sub_active_paid_users",
    "sub_upgrades",
    "sub_new_paid_users",
    "sub_new_free_trial_users",
    "sub_voluntary_cancellations",
    "sub_involuntary_cancellations",
    "sub_free_trial_converted_users",
    "sub_downgrades",
    "sub_total_users",
    "sub_trial_users",
    "sub_installed_devices"
  ],

  adopsGraphTypeDropdown: [
    {
      color: "rgb(18, 221, 66, 0.63)",
      value: "ad_revenue",
      label: "Revenue",
    },
    {
      color: "rgb(243,149,0,0.63)",
      value: "ad_impressions",
      label: "Impression",
    },
    {
      color: "rgb(98, 183, 184, 0.63)",
      value: "ad_fill_rate",
      label: "Fill Rate ",
    },
    {
      color: "rgba(8, 181, 238, 0.63)",
      value: "ad_bid_auctions",
      label: "Bid Auctions",
    },
    {
      color: "rgb(84, 138, 62, 0.63)",
      value: "ad_unique_users",
      label: "Unique Users",
    },
    {
      color: "rgb(233, 64, 5, 0.63)",
      value: "ad_cpm",
      label: "CPM",
    },
    {
      color: "rgb(0, 35, 139, 0.63)",
      value: "ad_render_rate",
      label: "Render Rate",
    },
    {
      color: "rgb(180, 25, 167, 0.63)",
      value: "ad_bid_wons",
      label: "Bids Won",
    },
    {
      color: "rgb(255, 20, 147, 0.63)",
      value: "pod_time_requested",
      label: "Pod Time Requested",
    },
    {
      color: "rgb(165, 42, 42, 0.63)",
      value: "pod_time_filled",
      label: "Pod Time Filled",
    },
    {
      color: "rgb(47, 79, 79, 0.63)",
      value: "pod_slot_requests",
      label: "Pod Slot Requests",
    },
    {
      color: "rgb(47, 96, 79, 0.63)",
      value: "pod_time_returned",
      label: "Pod Time Returned",
    },
  ],
  graphTypeDropdownOTT: [
    {
      color: "rgb(18, 221, 66, 0.63)",
      value: "ad_revenue",
      label: "Ad Revenue",
    },
    {
      color: "rgb(243,149,0,0.63)",
      value: "ad_impressions",
      label: "Impression",
    },
    {
      color: "rgb(98, 183, 184, 0.63)",
      value: "ad_fill_rate",
      label: "Fill Rate ",
    },
    {
      color: "rgba(8, 181, 238, 0.63)",
      value: "ad_bid_auctions",
      label: "Bid Auctions",
    },
    {
      color: "rgb(84, 138, 62, 0.63)",
      value: "ad_unique_users",
      label: "Ad Unique Users",
    },
    {
      color: "rgb(233, 64, 5, 0.63)",
      value: "ad_cpm",
      label: "CPM",
    },
    {
      color: "rgb(0, 35, 139, 0.63)",
      value: "ad_render_rate",
      label: "Render Rate",
    },
    {
      color: "rgb(180, 25, 167, 0.63)",
      value: "ad_bid_wons",
      label: "Bids Won",
    },
    {
      color: "rgb(51, 11, 98, 0.63)",
      value: "total_cost",
      label: "Total Cost",
    },
    {
      color: "rgb(29, 117, 117, 0.63)",
      value: "gb_streamed",
      label: "GB Streamed",
    },
    {
      color: "rgb(17, 45, 84, 0.63)",
      value: "revenue_per_view_minute",
      label: "Revenue/View Time(min)",
    },
    {
      color: "rgb(144, 159, 17, 0.63)",
      value: "views_per_user",
      label: "Views/User",
    },
    {
      color: "rgb(94, 74, 150, 0.63)",
      value: "viewtime_per_user",
      label: "View Time/User",
    },
    {
      color: "rgb(187, 160, 50, 0.63)",
      value: "revenue_per_user",
      label: "Revenue/User",
    },
    {
      color: "rgb(253, 53, 83, 0.63)",
      value: "revenue",
      label: "Revenue",
    },
    {
      color: "rgb(244, 120, 81, 0.63)",
      value: "cost_delivery",
      label: "Delivery Cost",
    },
    {
      color: "rgb(92, 53, 42, 0.63)",
      value: "music_cost",
      label: "Music Cost",
    },
    {
      color: "rgb(100, 181, 246, 0.63)",
      value: "views",
      label: "Views",
    },
    {
      color: "rgb(178, 227, 231, 0.63)",
      value: "minutes_per_view",
      label: "Mins/View",
    },
    {
      color: "rgb(21, 158, 160, 0.63)",
      value: "viewtime",
      label: "View Time",
    },
    {
      color: "rgb(62, 39, 35, 0.63)",
      value: "users",
      label: "Users",
    },
    {
      color: "rgb(0, 142, 228, 0.63)",
      value: "cost_gb_streamed",
      label: "CDN Cost",
    },
    {
      color: "rgb(221, 135, 205, 0.63)",
      value: "revenue_per_view",
      label: "Revenue/View",
    },
    {
      color: "rgb(13, 59, 102, 0.63)",
      value: "ad_frequency",
      label: "Ad Frequency",
    },
  ],
  graphTypeDropdown: [
    {
      color: "rgb(18, 221, 66, 0.63)",
      value: "ad_revenue",
      label: "Ad Revenue",
    },
    {
      color: "rgb(243,149,0,0.63)",
      value: "ad_impressions",
      label: "Impression",
    },
    {
      color: "rgb(98, 183, 184, 0.63)",
      value: "ad_fill_rate",
      label: "Fill Rate ",
    },
    {
      color: "rgba(8, 181, 238, 0.63)",
      value: "ad_bid_auctions",
      label: "Bid Auctions",
    },
    {
      color: "rgb(84, 138, 62, 0.63)",
      value: "ad_unique_users",
      label: "Ad Unique Users",
    },
    {
      color: "rgb(233, 64, 5, 0.63)",
      value: "ad_cpm",
      label: "CPM",
    },
    {
      color: "rgb(0, 35, 139, 0.63)",
      value: "ad_render_rate",
      label: "Render Rate",
    },
    {
      color: "rgb(180, 25, 167, 0.63)",
      value: "ad_bid_wons",
      label: "Bids Won",
    },
    {
      color: "rgb(51, 11, 98, 0.63)",
      value: "total_cost",
      label: "Total Cost",
    },
    {
      color: "rgb(29, 117, 117, 0.63)",
      value: "gb_streamed",
      label: "GB Streamed",
    },
    {
      color: "rgb(17, 45, 84, 0.63)",
      value: "revenue_per_view_minute",
      label: "Revenue/View Time(min)",
    },
    {
      color: "rgb(144, 159, 17, 0.63)",
      value: "views_per_user",
      label: "Views/User",
    },
    {
      color: "rgb(94, 74, 150, 0.63)",
      value: "viewtime_per_user",
      label: "View Time/User",
    },
    {
      color: "rgb(187, 160, 50, 0.63)",
      value: "revenue_per_user",
      label: "Revenue/User",
    },
    {
      color: "rgb(253, 53, 83, 0.63)",
      value: "revenue",
      label: "Revenue",
    },
    {
      color: "rgb(244, 120, 81, 0.63)",
      value: "cost_delivery",
      label: "Delivery Cost",
    },
    {
      color: "rgb(239, 140, 152, 0.63)",
      value: "reported_revenue",
      label: "Reported Revenue",
    },
    {
      color: "rgb(92, 53, 42, 0.63)",
      value: "music_cost",
      label: "Music Cost",
    },
    {
      color: "rgb(100, 181, 246, 0.63)",
      value: "views",
      label: "Views",
    },
    {
      color: "rgb(178, 227, 231, 0.63)",
      value: "minutes_per_view",
      label: "Mins/View",
    },
    {
      color: "rgb(21, 158, 160, 0.63)",
      value: "viewtime",
      label: "View Time",
    },
    {
      color: "rgb(62, 39, 35, 0.63)",
      value: "users",
      label: "Users",
    },
    {
      color: "rgb(0, 142, 228, 0.63)",
      value: "cost_gb_streamed",
      label: "CDN Cost",
    },
    {
      color: "rgb(221, 135, 205, 0.63)",
      value: "revenue_per_view",
      label: "Revenue/View",
    },
    {
      color: "rgb(13, 59, 102, 0.63)",
      value: "ad_frequency",
      label: "Ad Frequency",
    },
    {
      color: "rgb(215,204,200, 0.63)",
      value: "revenue_per_transaction",
      label: "Revenue/Transactions",
    },
    {
      color: "rgb(220, 237, 200, 0.63)",
      value: "transactions",
      label: "Transactions",
    },
    {
      color: "rgb(141, 177, 178, 0.63)",
      value: "estimated_revenue",
      label: "Estimated Revenue",
    },
    {
      color: "rgb(177, 156, 217, 0.63)",
      value: "gross_revenue",
      label: "Gross Revenue",
    },
    {
      color: "rgb(255, 20, 147, 0.63)",
      value: "ad_pod_time_requested",
      label: "Pod Time Requested",
    },
    {
      color: "rgb(165, 42, 42, 0.63)",
      value: "ad_pod_time_filled",
      label: "Pod Time Filled",
    },
    {
      color: "rgb(47, 79, 79, 0.63)",
      value: "ad_pod_slot_requests",
      label: "Pod Slot Requests",
    },
    {
      color: "rgb(47, 96, 79, 0.63)",
      value: "ad_pod_time_returned",
      label: "Pod Time Returned",
    },
    {
      color: "rgb(47, 90, 79, 0.63)",
      value: "sub_revenue",
      label: "Subscription Revenue",
    },
    {
      color: "rgb(79, 90, 23, 0.63)",
      value: "sub_cancelled_users",
      label: "Subscription Cancelled Users",
    },
    {
      color: "rgb(47, 66, 12, 0.63)",
      value: "sub_active_paid_users",
      label: "Subscription Active Paid Users",
    },
    {
      color: "rgb(56, 90, 79, 0.63)",
      value: "sub_upgrades",
      label: "Subscription Upgrades",
    },
    {
      color: "rgb(123, 90, 45, 0.63)",
      value: "sub_new_paid_users",
      label: "Subscription New Paid Users",
    },
    {
      color: "rgb(98, 98, 44, 0.63)",
      value: "sub_new_free_trial_users",
      label: "Subscription New Free Trial Users",
    },
    {
      color: "rgb(234, 99, 12, 0.63)",
      value: "sub_voluntary_cancellations",
      label: "Subscription Voluntary Cancellations",
    },
    {
      color: "rgb(76, 2, 97, 0.63)",
      value: "sub_churn_rate",
      label: "Subscription Churn Rate",
    },
    {
      color: "rgb(45, 66, 99, 0.63)",
      value: "sub_involuntary_cancellations",
      label: "Subscription Involuntary Cancellations",
    },
    {
      color: "rgb(47, 145, 87, 0.63)",
      value: "sub_free_trial_converted_users",
      label: "Subscription Free Trial Converted Users",
    },
    {
      color: "rgb(47, 212, 79, 0.63)",
      value: "sub_downgrades",
      label: "Subscription Downgrades",
    },
    {
      color: "rgb(234, 95, 87, 0.63)",
      value: "sub_total_users",
      label: "Subscription Total Users",
    },
    {
      color: "rgb(47, 26, 88, 0.63)",
      value: "sub_trial_users",
      label: "Subscription Trial Users",
    },
    {
      color: "rgb(47, 26, 57, 0.63)",
      value: "sub_installed_devices",
      label: "Installed Devices",
    },
    {
      color: "rgb(47, 26, 145, 0.63)",
      value: "sub_revenue_per_user",
      label: "Subscription Revenue/Users",
    },
    
  ],
  graphTypeDropdownOTTAnnualOverall: [    
    {
      color: "rgb(253, 53, 83, 0.63)",
      value: "revenue",
      label: "Revenue",
    },
    {
      color: "rgb(100, 181, 246, 0.63)",
      value: "views",
      label: "Views",
    },
    {
      color: "rgb(21, 158, 160, 0.63)",
      value: "viewtime",
      label: "View Time",
    },
    {
      color: "rgb(62, 39, 35, 0.63)",
      value: "users",
      label: "Users",
    },    
  ],
  graphTypeDropdownOTTDailyOverall: [    
    {
      color: "rgb(18, 221, 66, 0.63)",
      value: "ad_revenue",
      label: "Ad Revenue",
    },
    {
      color: "rgb(243,149,0,0.63)",
      value: "ad_impressions",
      label: "Impression",
    },
    {
      color: "rgb(98, 183, 184, 0.63)",
      value: "ad_fill_rate",
      label: "Fill Rate ",
    },
    {
      color: "rgb(233, 64, 5, 0.63)",
      value: "ad_rpm",
      label: "RPM",
    },
    {
      color: "rgb(233, 64, 5, 0.63)",
      value: "ad_cpm",
      label: "CPM",
    },
    {
      color: "rgb(0, 35, 139, 0.63)",
      value: "ad_render_rate",
      label: "Render Rate",
    },
    {
      color: "rgb(47, 89, 145, 0.63)",
      value: "ad_cost",
      label: "Ad Cost",
    },
    {
      color: "rgb(78, 89, 111, 0.63)",
      value: "ad_opportunities",
      label: "Opportunities",
    },
    {
      color: "rgb(111, 89, 211, 0.63)",
      value: "ad_total_requests",
      label: "Total Requests",
    },
    {
      color: "rgb(51, 11, 98, 0.63)",
      value: "total_cost",
      label: "Total Cost",
    },
    {
      color: "rgb(29, 117, 117, 0.63)",
      value: "gb_streamed",
      label: "GB Streamed",
    },
    {
      color: "rgb(17, 45, 84, 0.63)",
      value: "revenue_per_view_minute",
      label: "Revenue/View Time(min)",
    },
    {
      color: "rgb(144, 159, 17, 0.63)",
      value: "views_per_user",
      label: "Views/User",
    },
    {
      color: "rgb(94, 74, 150, 0.63)",
      value: "viewtime_per_user",
      label: "View Time/User",
    },
    {
      color: "rgb(187, 160, 50, 0.63)",
      value: "revenue_per_user",
      label: "Revenue/User",
    },
    {
      color: "rgb(253, 53, 83, 0.63)",
      value: "revenue",
      label: "Revenue",
    },
    {
      color: "rgb(244, 120, 81, 0.63)",
      value: "cost_delivery",
      label: "Delivery Cost",
    },
    {
      color: "rgb(92, 53, 42, 0.63)",
      value: "music_cost",
      label: "Music Cost",
    },
    {
      color: "rgb(100, 181, 246, 0.63)",
      value: "views",
      label: "Views",
    },
    {
      color: "rgb(178, 227, 231, 0.63)",
      value: "minutes_per_view",
      label: "Mins/View",
    },
    {
      color: "rgb(21, 158, 160, 0.63)",
      value: "viewtime",
      label: "View Time",
    },
    {
      color: "rgb(62, 39, 35, 0.63)",
      value: "users",
      label: "Users",
    },
    {
      color: "rgb(0, 142, 228, 0.63)",
      value: "cost_gb_streamed",
      label: "CDN Cost",
    },
    {
      color: "rgb(221, 135, 205, 0.63)",
      value: "revenue_per_view",
      label: "Revenue/View",
    },
    {
      color: "rgb(13, 59, 102, 0.63)",
      value: "ad_frequency",
      label: "Ad Frequency",
    },
    {
      color: "rgb(255, 20, 147, 0.63)",
      value: "ad_pod_time_requested",
      label: "Pod Time Requested",
    },
    {
      color: "rgb(165, 42, 42, 0.63)",
      value: "ad_pod_time_filled",
      label: "Pod Time Filled",
    },
    {
      color: "rgb(47, 79, 79, 0.63)",
      value: "ad_pod_slot_requests",
      label: "Pod Slot Requests",
    },
    {
      color: "rgb(47, 96, 79, 0.63)",
      value: "ad_pod_time_returned",
      label: "Pod Time Returned",
    },
    {
      color: "rgb(47, 90, 79, 0.63)",
      value: "ad_pod_slots_returned",
      label: "Pod Slot Returned",
    },
    {
      color: "rgb(47, 90, 79, 0.63)",
      value: "sub_revenue",
      label: "Subscription Revenue",
    },
    {
      color: "rgb(79, 90, 23, 0.63)",
      value: "sub_cancelled_users",
      label: "Subscription Cancelled Users",
    },
    {
      color: "rgb(47, 66, 12, 0.63)",
      value: "sub_active_paid_users",
      label: "Subscription Active Paid Users",
    },
    {
      color: "rgb(56, 90, 79, 0.63)",
      value: "sub_upgrades",
      label: "Subscription Upgrades",
    },
    {
      color: "rgb(123, 90, 45, 0.63)",
      value: "sub_new_paid_users",
      label: "Subscription New Paid Users",
    },
    {
      color: "rgb(98, 98, 44, 0.63)",
      value: "sub_new_free_trial_users",
      label: "Subscription New Free Trial Users",
    },
    {
      color: "rgb(234, 99, 12, 0.63)",
      value: "sub_voluntary_cancellations",
      label: "Subscription Voluntary Cancellations",
    },
    {
      color: "rgb(76, 2, 97, 0.63)",
      value: "sub_churn_rate",
      label: "Subscription Churn Rate",
    },
    {
      color: "rgb(45, 66, 99, 0.63)",
      value: "sub_involuntary_cancellations",
      label: "Subscription Involuntary Cancellations",
    },
    {
      color: "rgb(47, 145, 87, 0.63)",
      value: "sub_free_trial_converted_users",
      label: "Subscription Free Trial Converted Users",
    },
    {
      color: "rgb(47, 212, 79, 0.63)",
      value: "sub_downgrades",
      label: "Subscription Downgrades",
    },
    {
      color: "rgb(234, 95, 87, 0.63)",
      value: "sub_total_users",
      label: "Subscription Total Users",
    },
    {
      color: "rgb(47, 26, 88, 0.63)",
      value: "sub_trial_users",
      label: "Subscription Trial Users",
    },
    {
      color: "rgb(47, 26, 145, 0.63)",
      value: "sub_revenue_per_user",
      label: "Subscription Revenue/Users",
    },
    {
      color: "rgb(47, 26, 57, 0.63)",
      value: "sub_installed_devices",
      label: "Installed Devices",
    },
  ],
  graphTypeDropdownLeaderboard: [
    {
      color: "rgb(18, 221, 66, 0.63)",
      value: "ad_revenue",
      label: "Ad Revenue",
    },
    {
      color: "rgb(51, 11, 98, 0.63)",
      value: "total_cost",
      label: "Total Cost",
    },
    {
      color: "rgb(29, 117, 117, 0.63)",
      value: "gb_streamed",
      label: "GB Streamed",
    },
    {
      color: "rgb(244, 120, 81, 0.63)",
      value: "cost_delivery",
      label: "Delivery Cost",
    },
    {
      color: "rgb(92, 53, 42, 0.63)",
      value: "music_cost",
      label: "Music Cost",
    },
    {
      color: "rgb(100, 181, 246, 0.63)",
      value: "views",
      label: "Views",
    },
    {
      color: "rgb(21, 158, 160, 0.63)",
      value: "viewtime",
      label: "View Time",
    },
    {
      color: "rgb(239, 140, 152, 0.63)",
      value: "reported_revenue",
      label: "Reported Revenue",
    },
    {
      color: "rgb(253, 53, 83, 0.63)",
      value: "revenue",
      label: "Revenue",
    },
  ],

  graphCodeObj: {
    africachannel: {
      color: "rgb(0, 64, 141, 0.63)",
      value: "africachannel",
      label: "AfricaChannel",
      short: "AC",
      logo: AfricaChannelLogo,
    },
    alliedvaughn: {
      color: "rgb(0, 64, 141, 0.63)",
      value: "alliedvaughn",
      label: "Allied Vaughn",
      short: "AL",
      logo: AlliedVaughnleLogo,
    },
    amagi: {
      color: "rgb(0, 0, 160, 0.63)",
      value: "amagi",
      label: "Amagi",
      short: "A",
      logo: AmagiLogo,
    },
    amazon: {
      color: "rgb(243,149,0,0.63)",
      value: "amazon",
      label: "Amazon",
      short: "AZ",
      logo: AmazonLogo,
    },
    amazonprime: {
      color: "rgb(243,149,0,0.63)",
      value: "amazonprime",
      label: "Amazon Prime",
      short: "AP",
      logo: AmazonPrimeVideoLogo,
    },
    amazon_prime: {
      color: "rgb(243,149,0,0.63)",
      value: "amazon_prime",
      label: "Amazon Prime",
      short: "AP",
      logo: AmazonPrimeVideoLogo,
    },
    amazonestvod: {
      color: "rgb(0, 64, 141, 0.63)",
      value: "amazonestvod",
      label: "Amazon EST-VOD",
      short: "AEV",
      logo: AmazonEstVODLogo
    },
    amazonpvd: {
      color: "rgb(204, 255, 255, 0.63)",
      value: "amazonpvd",
      label: "AmazonPVD",
      short: "APVD",
      logo: AmazonPrimeVideoLogo
    },
    android:{
      color: "rgb(204, 255, 255, 0.63)",
      value: "android",
      label: "Android",
      short: "AN",
      logo: AndroidLogo
    },
    androidtv:{
      color: "rgb(204, 255, 255, 0.63)",
      value: "androidtv",
      label: "AndroidTV",
      short: "AndroidTV",
      logo: AndroidtvLogo,
    },
    apple: {
      color: "rgb(243,149,0,0.63)",
      value: "apple",
      label: "Apple",
      short: "AP",
      logo: AppleLogo,
    },
    asiancrush: {
      color: "rgb(244,132,66, 0.63)",
      value: "asiancrush",
      label: "AsianCrush ",
      short: "AS",
      logo: AsiancrushLogo,
    },
    atmosphere: {
      color: "rgb(98, 183, 184, 0.63)",
      value: "atmosphere",
      label: "Atomsphere ",
      short: "AT",
      logo: AtmosphereLogo,
    },
    auxmode: {
      color: "rgb(204, 255, 255, 0.63)",
      value: "auxmode",
      label: "AuxMode",
      short: "AM",
      logo: AuxModeLogo
    },
    bobross: {
      // "color": "#d2252a",
      color: "rgba(210, 37, 43, 0.63)",
      value: "bobross",
      label: "Bob Ross",
      short: "BR",
      logo: BobrossLogo,
    },
    bobrossespanol: {
      color: "rgba(252, 218, 124, 0.63)",
      value: "bobrossespanol",
      label: "Bob Ross Espanol",
      short: "BE",
      logo: BobRossEnEspanoLogo,
    },
    bambu: {
      color: "rgb(84, 138, 62, 0.63)",
      value: "bambu",
      label: "Bambu",
      short: "B",
      logo: BambooLogo,
    },
    bloodydisgusting: {
      color: "rgb(171, 3, 2, 0.63)",
      value: "bloodydisgusting",
      label: "Bloody Disgusting",
      short: "BD",
      logo: BloodyDisgustingLogo,
    },
    bingeblacktv:{
      color: "rgb(171, 3, 2, 0.63)",
      value: "bingeblacktv",
      label: "BingeBlack TV",
      short: "BBT",
      logo: BingeBlackTVLogo,
    },
    canela: {
      color: "rgb(0, 35, 139, 0.63)",
      value: "canela",
      label: "Canela",
      short: "CC",
      logo: Canela,
    },    
    canelatv: {
      color: "rgb(0, 35, 139, 0.63)",
      value: "canelatv",
      label: "CanelaTV",
      short: "CTV",
      logo: CanelaTVLogo,
    },
    christiancinema: {
      color: "rgb(0, 35, 139, 0.63)",
      value: "amazon",
      label: "Christian Cinema",
      short: "CH",
      logo: ChristianCinema,
    },
    "cidmdigital": {
      color: "rgb(98, 159, 202, 0.63)",
      value: "cidmdigital",
      label: "Cidm Digital",
      short: "CD",
      logo: CineDigm,
    },
    cinedigm: {
      color: "rgb(92,184,235, 0.63)",
      value: "cinedigm",
      label: "Cinedigm",
      short: "C",
      logo: CineDigm,
    },
    cineverse: {
      color: "rgb(0, 255, 186, 0.63)",
      value: "cineverse",
      label: "Cineverse",
      short: "CI",
      logo: cineverse,
    },
    cineverse_hubs: {
      color: "rgb(0, 255, 186, 0.63)",
      value: "cineverse_hubs",
      label: "Cineverse Hub",
      short: "CH",
      logo: CineverseHubLogo,
    }, 
    cocoro: {
      color: "rgba(158,201,30, 0.63)",
      value: "cocoro",
      label: "Cocoro",
      short: "CO",
      logo: CocoroLogo,
    },
    combatgo: {
      color: "rgb(204, 255, 255, 0.63)",
      value: "combatgo",
      label: "Combat Go",
      short: "CG",
      logo: CombatgoLogo,
    },
    comedydynamics: {
      color: "rgb(249, 182, 0, 0.63)",
      value: "comedydynamics",
      label: "Comedy Dynamics",
      short: "CM",
      logo: ComedydyanamicsLogo,
    },
    "comedy-dynamics": {
      color: "rgb(249, 182, 0, 0.63",
      value: "comedy-dynamics",
      label: "Comedy Dynamics",
      short: "CM",
      logo: ComedydyanamicsLogo,
    },
    "comedydynamics-clean": {
      color: "rgb(249, 182, 0, 0.63",
      value: "comedydynamics-clean",
      label: "Comedydynamics Clean",
      short: "CDC",
      logo: ComedydynamicsClean,
    },
    "comedydynamicsclean": {
      color: "rgb(249, 182, 0, 0.63",
      value: "comedydynamicsclean",
      label: "Comedydynamics Clean",
      short: "CDC",
      logo: ComedydynamicsClean,
    },
    common_delivery_papercup: {
      color: "rgb(245, 76, 52, 0.63)",
      value: "common_delivery_papercup",
      label: "Common Delivery Papercup",
      short: "CDP",
      logo: PaperCupCmn,
    },
    common_delivery_spherex: {
      color: "rgb(58, 148, 198, 0.63)",
      value: "common_delivery_spherex",
      label: "Common Delivery Spherex",
      short: "CDS",
      logo: SpherexCmn,
    },
    contv: {
      color: "rgb(252, 211, 43, 0.63)",
      value: "contv",
      label: "CONtv",
      short: "CN",
      logo: ContvLogo,
    },
    contvanime: {
      color: "rgb(212, 33, 93, 0.63)",
      value: "contvanime",
      label: "CONtv Anime",
      short: "CA",
      logo: ContvAnimLogo,
    },
    comcast: {
      color: "rgb(8, 81, 101, 0.63)",
      value: "Comcast",
      label: "Comcast",
      short: "Comcast",
      logo: ComcastLogo,
    },
    cox: {
      color: "rgb(8, 81, 101, 0.63)",
      value: "cox",
      label: "Cox",
      short: "Cox",
      logo: coxLogo,
    },
    crackle: {
      color: "rgb(8, 81, 101, 0.63)",
      value: "crackle",
      label: "Crackle",
      short: "Crackle",
      logo: CrackleLogo,
    },
    crimehunters: {
      color: "rgba(52,24,2, 0.63)",
      value: "crimehunters",
      label: "CrimeHunters",
      short: "CR",
      logo: crimehuntersLogo,
    },
    darkmattertv: {
      color: "rgb(8, 81, 101, 0.63)",
      value: "darkmattertv",
      label: "DarkmatterTV",
      short: "DTV",
      logo: DarkmatterTVLogo,
    },
    dish_est: {
      color: "rgb(213, 43, 57, 0.63)",
      value: "dish_est",
      label: "Dish Est",
      short: "DE",
      logo: DishEst,
    },
    dish_network: {
      color: "#54ced4",
      value: "dish_network",
      label: "Dish Network",
      short: "DN",
      logo: DishNet,
    },
    dish: {
      color: "rgb(3, 43, 57, 0.63)",
      value: "dish",
      label: "Dish",
      short: "DH",
      logo: dishLogo,
    },
    distributionsolutions: {
      color: "rgb(58, 84, 122, 0.63)",
      value: "distributionsolutions",
      label: "Distributions Solutions",
      short: "DS",
      logo: DistributionSolutions,
    },
    distro: {
      color: "rgb(235, 77, 33, 0.63)",
      value: "distro",
      label: "Distro",
      short: "D",
      logo: DistroLogo,
    },
    docurama: {
      color: "rgb(39, 34, 89, 0.63)", //"#49DCB1",
      value: "docurama",
      label: "Docurama",
      short: "DC",
      logo: DocuramaLogo,
    },
    dove: {
      color: "rgb(45,194,233,0.63)",
      value: "dove",
      label: "Dove",
      short: "DV",
      logo: DoveLogo,
    },
    digital: {      
      color: "rgb(89,194,233,0.63)",
      value: "digital",
      label: "Digital",
      short: "Digital",
    },
    est: {
      color: "rgb(225, 190, 231, 0.63)",
      value: "est",
      label: "EST",
      short: "EST",
      logo: '',
    },
    elrey: {
      color: "rgb(238, 162, 71, 0.63)",
      value: "elrey",
      label: "El Rey",
      short: "ER",
      logo: ElReyLogo,
    },
    fandor: {
      color: "rgb(216, 200, 125, 0.63)",
      value: "fandor",
      label: "Fandor",
      short: "F",
      logo: Fandor,
    },
    fandangonow: {
      color: "rgb(216, 200, 125, 0.63)",
      value: "fandangonow",
      label: "FandangoNOW",
      short: "FGN",
      logo: FandangoNowLogo,
    },
    fantawild: {
      color: "rgb(237, 109, 0, 0.63)",
      value: "fantawild",
      label: "Fantawild",
      short: "FW",
      logo: FantawildLogo,
    },
    fashionbox: {
      color: "rgb(194, 25, 117, 0.63)",
      value: "fashionbox",
      label: "Fashionbox",
      short: "FB",
      logo: FasionboxLogo,
    },
    flixfling: {
      color: "rgb(248, 158, 0, 0.63)",
      value: "flixfling",
      label: "Flix Fling",
      short: "FF",
      logo: FlixFling,
    },
    frndly: {
      color: "rgb(8, 81, 101, 0.63)",
      value: "frndly",
      label: "Frndly",
      short: "FDL",
      logo: FrndlyLogo,
    },
    fubotv:{
      color: "rgb(8, 81, 101, 0.63)",
      value: "fubotv",
      label: "FuboTV",
      short: "FTV",
      logo: FuboTVLogo,
    },
    fuse:{
      color: "rgb(8, 81, 101, 0.63)",
      value: "fuse",
      label: "Fuse",
      short: "F",
      logo: FuseLogo,
    },
    futuretoday:{
      color: "rgb(8, 81, 101, 0.63)",
      value: "futuretoday",
      label: "Future Today",
      short: "FT",
      logo: FutureTodayLogo,
    },
    gci:{
      color: "rgb(8, 81, 101, 0.63)",
      value: "gci",
      label: "GCI",
      short: "GCI",
      logo: GCILogo,
    },
    gametoon: {
      color: "rgb(226, 75, 38, 0.63)",
      value: "gametoon",
      label: "Gametoon",
      short: "G",
      logo: GametoonLogo,
    },
    hallypop: {
      color: "rgb(153, 255, 204, 0.63)",
      value: "hallypop",
      label: "HallyPop",
      short: "HP",
      logo: HallyPopLogo,
    },
    happitv: {
      color: "rgb(255, 230, 255, 0.63)",
      value: "happitv",
      label: "HappiTV",
      short: "HT",
      logo: HappiTVLogo,
    },
    google_youtube: {
      color: "rgb(247, 0, 1, 0.63)",
      value: "google_youtube",
      label: "Google Youtube",
      short: "GY",
      logo: GoogleYoutube,
    },
    google: {
      color: "rgb(247, 0, 1, 0.63)",
      value: "google",
      label: "Google",
      short: "G",
      logo: GoogleLogo,
    },
    gumgum: {
      color: "rgb(103, 194, 193, 0.63)",
      value: "gumgum",
      label: "GumGum ",
      short: "GG",
      logo: GumGum,
    },
    gustotv: {
      color: "rgb(103, 194, 99, 0.63)",
      value: "gustotv",
      label: "Gusto Tv",
      short: "GT",
      logo: gustotvLogo,
    },
    hoopla: {
      color: "rgb(0, 169, 233, 0.63)",
      value: "hoopla",
      label: "Hoopla",
      short: "H",
      logo: Hoopla,
    },
    html5: {
      color: "rgb(0, 169, 233, 0.63)",
      value: "html5",
      label: "HTML5",
      short: "HTML5",
      logo: Html5Logo,
    },
    imdb: {
      color: "rgb(238, 191, 22, 0.63)",
      value: "imdb",
      label: "IMDb",
      short: "I",
      logo: IMdb,
    },
    indemand: {
      color: "rgb(38, 34, 96, 0.63)",
      value: "indemand",
      label: "In Demand",
      short: "ID",
      logo: InDemand,
    },
    indemand_est: {
      color: "rgb(38, 34, 96, 0.63)",
      value: "indemand_est",
      label: "In Demand Est",
      short: "IDE",
      logo: InDemandEst,
    },
    inapp: {
      color: "rgb(166, 30, 34, 0.63)",
      value: "inapp",
      label: "InApp",
      short: "IA",
      logo: InApp,
    },
    ios: {
      color: "rgb(166, 30, 34, 0.63)",
      value: "ios",
      label: "IOS",
      short: "IOS",
      logo: IosLogo,
    },
    ivod: {
      color: "rgb(239,154,154, 0.63)",
      value: "ivod",
      label: "IVOD",
      short: "IVOD",
      logo: '',
    },
    itunes: {
      color: "rgb(74, 193, 217, 0.63)",
      value: "itunes",
      label: "iTunes",
      short: "IT",
      logo: ITunes,
    },
    junction: {
      color: "rgb(242, 156, 24, 0.63)",
      value: "junction",
      label: "Matchpoint ",
      short: "J",
      logo: MatchpointLogo,
    },
    kanopy:{
      color: "rgb(242, 156, 24, 0.63)",
      value: "kanopy",
      label: "Kanopy ",
      short: "KN",
      logo: KanopyLogo,
    },
    kartoonchannel:{
      color: "rgb(242, 156, 24, 0.63)",
      value: "kartoonchannel",
      label: "Kartoon Channel ",
      short: "KC",
      logo: KartoonChannelLogo,
    },
    klowd: {
      color: "rgb(241,146,28,0.63)",
      value: "klowd",
      label: "Klowd",
      short: "K",
      logo: Klowd,
    },
    klowdtv: {
      color: "rgb(241,146,28,0.63)",
      value: "klowdtv",
      label: "Klowd Tv",
      short: "KT",
      logo: KlowdTV,
    },
    kmtv: {
      color: "rgba(239,221,101,0.63)",
      value: "kmtv",
      label: "KMTV",
      short: "KM",
      logo: KMTVLogo,
    },
    kortv: {
      color: "rgba(194,46,118,0.63)",
      value: "kortv",
      label: "KORTV",
      short: "KO",
      logo: KorTVLogo,
    },
    lg: {
      color: "rgb(165, 195, 232, 0.63)",
      value: "lg",
      label: "LG",
      short: "LG",
      logo: LGLogo,
    },
    littlstar: {
      color: "rgb(24, 121, 179, 0.63)",
      value: "littlstar",
      label: "LittlStar",
      short: "LS",
      logo: LittlStar,
    },
    localnow: {
      color: "rgb(6, 124, 133, 0.63)",
      value: "localnow",
      label: "LocalNow",
      short: "LN",
      logo: LocalNow,
    },
    lonestar: {
      color: "rgb(178, 26, 40, 0.63)",
      value: "lonestar",
      label: "Lone Star",
      short: "LO",
      logo: LoneStarLogo,
    },
    matchpoint: {
      color: "rgb(236, 28, 35, 0.63)",
      value: "matchpoint",
      label: "Matchpoint",
      short: "M",
      logo: MatchpointLogo,
    },
    maverick: {
      color: "rgb(236, 28, 203, 0.63)",
      value: "maverick",
      label: "Maverick",
      short: "MA",
      logo: MaverickLogo,
    },
    microsoft: {
      color: "rgba(0,186,237, 0.63)",
      value: "microsoft",
      label: "Microsoft",
      short: "MS",
      logo: MicrosoftLogo,
    },
    midnightpulp: {
      color: "rgba(0,186,237, 0.63)",
      value: "midnightpulp",
      label: "MidnightPulp",
      short: "MP",
      logo: MidnightPulpLogo,
    },
    mxplayer: {
      color: "rgb(51, 155, 175, 0.63)",
      value: "mxplayer",
      label: "MXPlayer",
      short: "MP",
      logo: MXPlayerLogo,
    },
    mytime: {
      color: "rgb(51, 155, 175, 0.63)",
      value: "mytime",
      label: "MyTime",
      short: "MT",
      logo: MytimeLogo,
    },
    newvideo: {
      color: "rgb(245, 248, 225)",
      value: "newvideo",
      label: "New Video",
      short: "NV",
      logo: NewVideo,
    },
    nreal: {
      color: "rgb(8, 81, 101, 0.63)",
      value: "nreal",
      label: "NReal",
      short: "nreal",
      logo: NrealLogo,
    },
    ono: {
      color: "rgb(149, 234, 233, 0.63)",
      value: "ono",
      label: "ONO",
      short: "ONO",
      logo: ONOLogo,
    },
    ottera: {
      color: "rgb(172,32,32, 0.63)",
      value: "ottera",
      label: "OTTera",
      short: "OT",
      logo: otteraLogo,      
    },
    ott: {      
      color: "rgb(172,32,56, 0.63)",
      value: "ott",
      label: "OTT",
      short: "OTT",
    },
    overdrive: {
      color: "rgb(0, 97, 145, 0.63)",
      value: "overdrive",
      label: "One Drive",
      short: "OD",
      logo: OverDrive,
    },
    peacock: {
      color: "rgb(103, 58, 183, 0.63)",
      value: "peacock",
      label: "Peacock",
      short: "P",
      logo: Peacock,
    },
    philo: {
      color: "rgb(103, 98, 183, 0.63)",
      value: "philo",
      label: "Philo",
      short: "Ph",
      logo: philoLogo,
    },
    playstation: {
      color: "rgb(0,65,151, 0.63)",
      value: "playstation",
      label: "Play Station",
      short: "PS",
      logo: PlayStation,
    },
    plex: {
      color: "rgb(229, 167, 28, 0.63)",
      value: "plex",
      label: "Plex",
      short: "PL",
      logo: Plex,
    },
    plutotv: {
      color: "rgb(69, 13, 99, 0.63)",
      value: "plutotv",
      label: "Pluto",
      short: "PT",
      logo: Pluto,
    },
    pluto: {
      color: "rgb(69, 13, 99, 0.63)",
      value: "plutotv",
      label: "Pluto",
      short: "PT",
      logo: Pluto,
    },
    plutointernal: {
      color: "rgb(69, 13, 99, 0.63)",
      value: "plutointernal",
      label: "Pluto Internal",
      short: "PTI",
      logo: Pluto,
    },
    popcornflix: {
      color: "rgb(233, 29, 1, 0.63)",
      value: "popcornflix",
      label: "Popcorn Flix",
      short: "PF",
      logo: PopcornFlix,
    },
    qttv: {
      color: "rgb(237, 223, 223, 0.63)",
      value: "qttv",
      label: "QTTV",
      short: "QT",
      logo: qttv,      
    },
    qwest: {      
      color: "rgb(88, 223, 223, 0.63)",
      value: "qwest",
      label: "Qwest",
      short: "Qw",
      logo: qwestLogo, 
    },
    rakuten: {
      color: "rgb(186, 0, 0, 0.63)",
      value: "rakuten",
      label: "Rakuten ",
      short: "R",
      logo: Rakuten,
    },
    redbox: {
      color: "rgb(225, 34, 74, 0.63)",
      value: "redbox",
      label: "Redbox",
      short: "RD",
      logo: Redbox,
    },
    redboxavod: {
      color: "rgb(225, 34, 74, 0.63)",
      value: "redboxavod",
      label: "Redbox",
      short: "RB",
      logo: Redbox,
    },

    redbox_on_demand: {
      color: "rgb(225, 34, 74, 0.63)",
      value: "redbox_on_demand",
      label: "Redbox On Demand",
      short: "ROD",
      logo: RedboxDemand,
    },
    realmadrid: {
      color: "rgb(245, 186, 0, 0.63)",
      value: "realmadrid",
      label: "Real Madrid",
      short: "RM",
      logo: RealMadridLogo,
    },
    rebox: {
      color: "rgb(71, 178, 71, 0.6)",
      value: "rebox",
      label: "Rebox",
      short: "RB",
      logo: Rebox,
    },
    rentrak: {
      color: "rgb(71, 178, 71, 0.6)",
      value: "rentrak",
      label: "Rentrak",
      short: "RB",
      logo: RentrakLogo,
    },
    retrocrush: {
      color: "rgba(235,36,63, 0.6)",
      value: "retrocrush",
      label: "RetroCrush",
      short: "RC",
      logo: RetrocrushLogo,
    },
    rlaxxtv:{
      color: "rgba(235,36,63, 0.6)",
      value: "rlaxxtv",
      label: "RlaxxTV",
      short: "RLT",
      logo: RlaxxTVLogo,
    },
    rockbot: {
      color: "rgba(235,36,63, 0.6)",
      value: "rockbot",
      label: "Rockbot",
      short: "RC",
      logo: RockbotLogo,
    },
    roku: {
      color: "rgb(77, 7, 144, 0.63)",
      value: "roku",
      label: "Roku",
      short: "RK",
      logo: Roku,
    },
    rokunewman: {
      color: "rgb(77, 7, 144, 0.63)",
      value: "rokunewman",
      label: "Roku-Newman",
      short: "RKN",
      logo: RokuNewManLogo,
    },
    row8: {
      color: "rgb(77, 77, 144, 0.63)",
      value: "row8",
      label: "Row8",
      short: "Row",
      logo: Row8Logo,
    },
    samsung: {
      color: "rgb(72,216,238, 0.63)",
      value: "samsung",
      label: "Samsung",
      short: "S",
      logo: SamsungLogo,
    },
    samsungtv: {
      color: "rgb(0, 82, 158, 0.63)",
      value: "samsungtv",
      label: "Samsung TV",
      short: "ST",
      logo: SamsungTV,
    },
    samsungtvmobile: {
      color: "rgb(148,188,200, 0.63)",
      value: "samsungtvmobile",
      label: "Samsung TV Mobile",
      short: "STM",
      logo: SamsungTvMobile,
    },
    samsungtvplus: {
      color: "rgb(0, 82, 158, 0.63)",
      value: "samsungtvplus",
      label: "Samsung TV Plus",
      short: "STVP",
      logo: SamsungTVplus,
    },
    sanctuary: {
      color: "rgb(182, 112, 174, 0.63)",
      value: "sanctuary",
      label: "Sanctuary",
      short: "S",
      logo: Sanctuary,
    },
    sbs:{
      color: "rgb(182, 112, 174, 0.63)",
      value: "sbs",
      label: "SBS",
      short: "SBS",
      logo: SBSLogo,
    },
    screambox: {
      color: "rgb(166, 40, 42, 0.63)",
      value: "screambox",
      label: "Screambox",
      short: "SB",
      logo: Screambox,
    },
    scripps: {
      color: "rgb(166, 40, 42, 0.63)",
      value: "scripps",
      label: "Scripps",
      short: "SC",
      logo: scrippsLogo,
    },
    selecttv: {
      color: "rgb(26, 108, 233, 0.63)",
      value: "selecttv",
      label: "SelectTV ",
      short: "ST",
      logo: SelectTV,
    },
    sidestream: {
      color: "rgb(26, 108, 233, 0.63)",
      value: "sidestream",
      label: "Sidestream ",
      short: "SS",
      logo: SidestreamLogo,
    },
    slmedia:{
      color: "rgb(26, 108, 233, 0.63)",
      value: "slmedia",
      label: "SLMedia",
      short: "SLM",
      logo: SLMediaLogo,
    },
    sling: {
      color: "rgb(26, 108, 233, 0.63)",
      value: "sling",
      label: "Sling",
      short: "SL",
      logo: SlingLogo,
    },
    so_dramatic: {
      color: "#7DC265",
      value: "so_dramatic",
      label: "So Dramatic",
      short: "SD",
      logo: SoDramatic,
    },
    sodramatic: {
      color: "rgb(219, 21, 21, 0.63)",
      value: "sodramatic",
      label: "So Dramatic",
      short: "SD",
      logo: SoDramatic,
    },
    soreal: {
      color: "rgb(26, 148, 23, 0.63)",
      value: "soreal",
      label: "SoReal",
      short: "SR",
      logo: SorealLogo,
    },
    so_real: {
      color: "rgb(26, 148, 23, 0.63)",
      value: "so_real",
      label: "so Real",
      short: "SR",
      logo: SorealLogo,
    },
    steam: {
      color: "rgb(248, 212, 56, 0.63)",
      value: "steam",
      label: "Steam",
      short: "SM",
      logo: SteamLogo,
    },
    stirr: {
      color: "rgb(248, 212, 56, 0.63)",
      value: "stirr",
      label: " STIRR",
      short: "ST",
      logo: STIRRLogo,
    },
    stremium: {
      color: "rgb(0, 166, 210, 0.63)",
      value: "stremium",
      label: "Stremium",
      short: "ST",
      logo: Stremium,
    },
    swank: {
      color: "rgb(42, 74, 124, 0.63)",
      value: "swank",
      label: "Swank",
      short: "SW",
      logo: Swank,
    },
    tcl: {
      color: "rgb(231, 26, 35, 0.63)",
      value: "tcl",
      label: "TCL",
      short: "TCL",
      logo: TCL,
    },
    thefilmdetective: {
      color: "rgb(235, 84, 54, 0.63)",
      value: "thefilmdetective",
      label: "The Film Detective",
      short: "TFD",
      logo: TheFilmDetectiveLogo,
    },
    tvos: {
      color: "rgb(235, 84, 54, 0.63)",
      value: "tvos",
      label: "TVOS",
      short: "TVOS",
      logo: TvosvLogo,
    },
    filmdetective: {
      color: "rgb(235, 84, 54, 0.63)",
      value: "thefilmdetective",
      label: "The Film Detective",
      short: "TFD",
      logo: TheFilmDetectiveLogo,
    },
    theelvispresleychannel: {
      color: "rgb(107, 158, 145, 0.63)",
      value: "theelvispresleychannel",
      label: "The Elvis Presley Channel",
      short: "TEC",
      logo: TheEvispresleyChannel,
    },
    theonlywayisessex: {
      color: "rgb(165, 195, 232, 0.63)",
      value: "theonlywayisessex",
      label: "The Only Way Is Essex",
      short: "TOWE",
      logo: TheOnlyWayIsEssexLogo,
    },
    thecountrynetwork: {
      color: "rgb(165, 195, 232, 0.63)",
      value: "thecountrynetwork",
      label: "The Country Network",
      short: "TCN",
      logo: TCN,
    },
    tothenew: {
      color: "rgb(165, 195, 232, 0.63)",
      value: "tothenew",
      label: "ToTheNew",
      short: "TN",
      logo: ToTheNewLogo,
    },
    toongoggles: {
      color: "rgb(165, 195, 232, 0.63)",
      value: "toongoggles",
      label: "Toon-Goggles",
      short: "TG",
      logo: ToonGooglesLogo,
    },
    towie: {
      color: "rgb(165, 195, 232, 0.63)",
      value: "towie",
      label: "Towie",
      short: "T",
      logo: TowieLogo,
    },
    tubitv: {
      color: "rgb(165, 195, 232, 0.63)",
      value: "tubitv",
      label: "Tubi TV",
      short: "TB",
      logo: TubiLogo,
    },
    trc: {
      color: "rgb(207, 210, 61, 0.63)",
      value: "trc",
      label: "TRC",
      short: "TRC",
      logo: Trc,
    },
    tubi: {
      color: "rgb(244, 55, 28, 0.63)",
      value: "tubi",
      label: "Tubi",
      short: "TB",
      logo: TubiLogo,
    },
    tvpass: {
      color: "rgb(244, 55, 28, 0.63)",
      value: "tvpass",
      label: "TVPass",
      short: "TP",
      logo: TVPassLogo,
    },
    twitch: {
      color: "rgb(97, 63, 160, 0.63)",
      value: "twitch",
      label: "Twitch",
      short: "TW",
      logo: TwitchLogo,
    },
    twistedmirror: {
      color: "rgb(97, 223, 160, 0.63)",
      value: "twistedmirror",
      label: "Twisted Mirror",
      short: "TWM",
      logo: TwistedMirrorLogo,
    },    
    ucast: {
      color: "rgb(97, 63, 160, 0.63)",
      value: "ucast",
      label: "uCast",
      short: "UC",
      logo: UCastLogo,
    },
    uptv: {
      color: "rgb(97, 63, 160, 0.63)",
      value: "uptv",
      label: "UP TV ",
      short: "UT",
      logo: UPTVLogo,
    },
    vamedia: {
      color: "rgb(225, 67, 21, 0.63)",
      value: "vamedia",
      label: "VAMedia",
      short: "VA",
      logo: VAMediaLogo,
    },
    vewd: {
      color: "rgb(225, 67, 21, 0.63)",
      value: "vewd",
      label: "Vewd",
      short: "V",
      logo: Vewd,
    },
    viewster: {
      color: "rgb(238, 147, 24, 0.63)",
      value: "viewster",
      label: "Viewster",
      short: "VS",
      logo: Viewster,
    },
    vimeo: {
      color: "rgb(238, 147, 24, 0.63)",
      value: "vimeo",
      label: "Vimeo",
      short: "VM",
      logo: VimeoLogo,
    },
    vizio: {
      color: "rgb(142, 114, 255, 0.63)",
      value: "vizio",
      label: "Vizio",
      short: "VZ",
      logo: Vizio,
    },
    vod: {
      color: "rgb(92, 107, 192, 0.63)",
      value: "vod",
      label: "VOD",
      short: "VOD",
      logo: '',
    },
    vsc: {
      color: "rgb(248, 90, 91, 0.63)",
      value: "vsc",
      label: "Vsc",
      short: "VSC",
      logo: Vsc,
    },
    vubiquity: {
      color: "rgb(234, 83, 34, 0.63)",
      value: "vubiquity",
      label: "Vubiquity",
      short: "VQ",
      logo: Vubiquity,
    },
    vubiquity_avod: {
      color: "rgb(234, 83, 34, 0.63)",
      value: "vubiquity_avod",
      label: "Vubiquity Avod",
      short: "VQA",
      logo: VubiquityAvod,
    },
    vudu: {
      color: "rgb(234, 83, 34, 0.63)",
      value: "vudu",
      label: "Vudu",
      short: "VD",
      logo: VuduLogo,
    },
    viziowatchfree: {
      color: "rgb(61, 192, 203, 0.63)",
      value: "viziowatchfree",
      label: "Vizio Watch Free",
      short: "WF",
      logo: "",
    },
    whistle: {
      color: "rgb(222, 40, 66, 0.63)",
      value: "whistle",
      label: "Whistle",
      short: "W",
      logo: WhistleLogo,
    },
    whistlesports: {
      color: "rgb(222, 40, 66, 0.63)",
      value: "whistlesports",
      label: "Whistle",
      short: "WS",
      logo: Whistlesports,
    },
    watchfree: {
      color: "rgb(61, 192, 203, 0.63)",
      value: "watchfree",
      label: "WatchFree",
      short: "WF",
      logo: WatchFree,
    },
    web: {
      color: "rgb(61, 192, 203, 0.63)",
      value: "web",
      label: "Web",
      short: "Web",
      logo: WebLogo,
    },
    wewatch: {
      color: "rgb(61, 192, 203, 0.63)",
      value: "wewatch",
      label: "WeWatch",
      short: "WW",
      logo: WeWatchLogo,
    },
    xbox:  {
      color: "rgb(255, 103, 1, 0.63)",
      value: "xbox",
      label: "XBOX",
      short: "XB",
      logo: XBOXLogo,
    },
    xiaomi:  {
      color: "rgb(255, 103, 1, 0.63)",
      value: "xiaomi",
      label: "Xiaomi",
      short: "X",
      logo: XiaomiLogo,
    },
    xumo: {
      color: "rgb(8, 81, 101, 0.63)",
      value: "xumo",
      label: "Xumo",
      short: "X",
      logo: XumoLogo,
    },
    xumolinear: {
      color: "rgb(8, 81, 101, 0.63)",
      value: "xumolinear",
      label: "Xumo Linear",
      short: "X",
      logo: XumoLogo,
    },
    youtubetv: {
      color: "rgb(8, 81, 101, 0.63)",
      value: "youtubetv",
      label: "YouTubeTV",
      short: "YT",
      logo: utubteTVLogo,
    },
    yuyu: {
      color: "rgb(8, 81, 101, 0.63)",
      value: "yuyu",
      label: "Yuyu",
      short: "YU",
      logo: YuyuLogo,
    },
    zype: {
      color: "rgb(8, 81, 101, 0.63)",
      value: "zype",
      label: "Zype",
      short: "Z",
      logo: ZypeLogo,
    },


    avod: {
      color: "rgb(8, 81, 101, 0.63)",
      value: "avod",
      label: "AVOD",
      short: "AVOD",
    },
    svod: {
      color: "rgb(61, 192, 203, 0.63)",
      value: "svod",
      label: "SVOD",
      short: "SVOD",
    },
    tvod: {
      color: "rgb(234, 83, 34, 0.63)",
      value: "tvod",
      label: "TVOD",
      short: "TVOD",
    },
    linear: {
      color: "rgb(222, 40, 66, 0.63)",
      value: "linear",
      label: "Linear",
      short: "Linear",
    },
    others: {
      color: "rgb(181, 209, 255, 0.63)",
      value: "Others",
      label: "others",
      short: "others",
    },
    view_count:  {
      color: "rgb(181, 209, 255, 0.63)",
      value: "view_count",
      label: "View Count",
      short: "View Count",
    },
    view_time:  {
      color: "rgb(234, 83, 34, 0.63)",
      value: "view_time",
      label: "View Time",
      short: "View Time",
    },
    user_count:  {
      color: "rgb(255, 103, 1, 0.63)",
      value: "user_count",
      label: "View Count",
      short: "View Count",
    },
  },

  Platform: {
    amazon: "amazon",
    matchpoint: "matchpoint",
    tubi: "tubi",
    xumo: "xumo",
    amagi: "amagi",
    amazon_prime: "amazon_prime",
    apple: "apple",
    comcast: "comcast",
    nreal: "nreal",
    google: "google",
    hoopla: "hoopla",
    microsoft: "microsoft",
    rentrak: "rentrak",
    redbox: "redbox",
    steam: "steam",
    vudu: "vudu",
    pluto: "pluto",
    ruko: "ruko", 
    xiaomi: "xiaomi",
    amazon_freevee: "amazon_freevee",
  },
  ViewerType: { avod: "avod", svod: "svod", tvod: "tvod", linear: "linear" },
  Channel: {
    bambu: "bambu",
    bloodydisgusting: "bloodydisgusting",
    bobross: "bobross",
    contv: "contv",
    comedydynamics: "comedydynamics",
    combatgo: "combatgo",
    contvanime: "contvanime",
    docurama: "docurama",
    dove: "dove",
    elrey: "elrey",
    fantawild: "fantawild",
    fandor: "fandor",
    fashionbox: "fashionbox",
    fightnet: "fightnet",
    happitv: "happitv",
    gametoon: "gametoon",
    lonestar: "lonestar",
    mytime: "mytime",
    rockbot: "rockbot",
    realmadrid: "realmadrid",
    sanctuary: "sanctuary",
    sodramatic: "sodramatic",
    soreal: "soreal",
    thefilmdetective: "thefilmdetective",
    towie: "towie",
    viewster: "viewster",
    whistletv: "whistletv",
  },
  fixedStartDate: 1625077800000,
  fixedEndDate: 1627756200000,
  AssetType: {
    movie: "movie",
    episode: "episode",
    episodic: "episodic",
    season: "season",
    comic_series: "comic_series",
    comic_issue: "comic_issue",
  },
  assetTypes: [
    { value: "", label: "All" },
    { value: "film", label: "Movie" },
    { value: "episode", label: "Episode" },
    { value: "episodic", label: "Series" },
    { value: "season", label: "Season" },
    { value: "comic_series", label: "Comic Series" },
    { value: "comic_issue", label: "Comic Issue" },
  ],

  viewerTypes: [
    { value: "avod", label: "AVOD" },
    { value: "svod", label: "SVOD" },
    { value: "tvod", label: "TVOD" },
    { value: "linear", label: "Linear" },
  ],

  viewerTypesOTTDaily: [
    { value: "avod", label: "AVOD" },
    { value: "svod", label: "SVOD" },
    { value: "linear", label: "Linear" },
  ],

  viewerTypeForDigital:[
    { value: "est", label: "EST"},
    { value: "ivod", label: "iVOD"},
    { value: "vod", label: "VOD"},
    { value: "tvvod", label: "TV-VOD"},
    { value: "svod", label: "SVOD"},
    { value: "avod", label: "AVOD"},
    { value: "nontheatrical", label: "Non-Theatrical"},
  ],

  viewerTypesOverviewLeaderboard: [
    { value: "avod", label: "AVOD" },
    { value: "est", label: "EST"},
    { value: "ivod", label: "iVOD"},
    { value: "linear", label: "Linear" },
    { value: "svod", label: "SVOD" },
  ],

  statusTypes: [
    { value: "", label: "Select Status" },
    { value: "error", label: "Error" },
    { value: "init", label: "Initalize" },
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
  ],
  archiveStatusList: [
    { value: "Error", label: "Error" },
    { value: "Initialized", label: "Initialized" },
    { value: "Pending", label: "Pending" },
    { value: "Completed", label: "Completed" },
    { value: "Archived", label: "Archived" },
    { value: "Finalized", label: "Finalized" },
  ],
  statusList: [
    { value: "error", label: "Error" },
    { value: "init", label: "Initalize" },
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
  ],
  linearChannelStatusList: [
    { value: "error", label: "Error" },
    { value: "completed", label: "Completed" },
  ],
  supplyRouterStatusList: [
    { value: "init", label: "Initalize" },
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
  ],
  amazonIDstatusList: [
    { value: "error", label: "Error" },
    { value: "init", label: "Initalize" },
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "ignored", label: "Ignored" }
  ],
  partnerIDstatusList: [
    { value: "error", label: "Error" },
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" }
  ],
  channelTypeOption: [
    { value: "-", label: "All" },
    { value: 'true' , label: "O&O" },
    { value: 'false' , label: "Third Party" },
  ],
  DateTypeOption: [
    { value: 'month' , label: "Month" },
    { value: 'week', label: "Week" },
    { value: 'day' , label: "Day" },
  ],
  DateTypeKPIOption: [
    { value: 'month' , label: "Month" },
    { value: 'year' , label: "Year" },
    { value: 'quarter' , label: "Quarter" },
    { value: 'week', label: "Week" },
    { value: 'day' , label: "Day" },
  ],
  DateTypeTopPerformerOption: [
    { value: 'month' , label: "Month" },
    { value: 'year' , label: "Year" },
    { value: 'quarter' , label: "Quarter" },
  ],
  DateTypeOptionAdOps: [
    { value: 'daily' , label: "Daily" },
    { value: 'weekly', label: "Weekly" },
    { value: 'monthly' , label: "Monthly" },
  ],
  chartTypeOption: [
    { value: "bar", label: "Bar Graph" },
    { value: 'area' , label: "Area Graph" },
    { value: 'line' , label: "Line Graph" },
  ],
  chartTypeWithSunburstOption: [
    { value: "bar", label: "Bar Graph" },
    { value: 'area' , label: "Area Graph" },
    { value: 'line' , label: "Line Graph" },
    { value: 'sunburst' , label: "Sunburst Graph" },
  ],
  chartTypeWithRibbonOption: 
  [
    { value: "bar", label: "Bar Graph" },
    { value: 'line' , label: "Line Graph" },
    { value: 'sunburst' , label: "Sunburst Graph" },
    { value: 'ribbon' , label: "Ribbon Graph" },
  ]
  ,
  chartTypeDefaultOption: [{ value: "bar", label: "Bar Graph" }],
  ingestDefaultStatusOption: [
      { value: "all", label: "All" },
      { value: "completed", label: "Completed" },
      { value: "error", label: "Error" },
      { value: "init", label: "Initialized" },
      { value: "ignored", label: "Ignored" }
  ],
  ingestReportStatusOptions :
  [
    { value: "all", label: "All" },
    { value: "completed", label: "Completed" },
    { value: "error", label: "Error" },
    { value: "markedError", label: "Marked as Error" },
    { value: "init", label: "Initialized" },
    { value: "pending", label: "Pending" },
    { value: "processing", label: "Processing" },
    { value: "finalized", label: "Finalized" },
  ],
  ingestPartnerStatusOptions : [
    { value: "all", label: "All" },
    { value: "completed", label: "Completed" },
    { value: "error", label: "Error" },
  ],
  itemTypeReportOption: [
    { value: "film", label: "Film" },
    { value: "episode", label: "Episode" }
  ],
  reportTypeReportOption: [
    { value: "all", label: "All" },
    { value: "performance", label: "Performance" },
    { value: "revenue", label: "Revenue" },
    { value: "cost", label: "Cost" },
  ],
  reportTypeDigitalOption: [
    { value: "pos", label: "POS"}
  ],

  RankingParameterOption: [
    { value: "viewtime", label: "Minutes Viewed"},
    { value: "revenue", label: "Revenue" },
    { value: "views", label: "View Count"},
    { value: "users", label: "User Count" },
    { value: "ad_impressions", label: "Ad Impressions" },
  ],

  hierarchicalParameterOption: [
    { value: "channel", label: "Channel"},
    { value: "platform", label: "Platform" },
    { value: "viewer_type", label: "Viewer Type"},
  ],

  partnerchanellogo: {
    bambu:
      "http://dashboard.matchpoint.tv/img/scm/channel-logo/bamboo_logo.png",
    contv: "http://dashboard.matchpoint.tv/img/scm/channel-logo/contv_logo.png",
    docurama:
      "http://dashboard.matchpoint.tv/img/scm/channel-logo/Docurama_logo.png",
    dove: "http://dashboard.matchpoint.tv/img/scm/channel-logo/dove_logo.png",
    comedydynamics:
      "http://dashboard.matchpoint.tv/img/scm/channel-logo/comedy_dynamics_logo.png",
    amazon:
      "http://dashboard.matchpoint.tv/img/scm/partner-logo/amazon-logo.png",
    matchpoint:
      "http://dashboard.matchpoint.tv/img/scm/MatchPointLogo_white.svg",
    tubi: "http://dashboard.matchpoint.tv/img/scm/partner-logo/tubitv_logo.png",
    xumo: "http://dashboard.matchpoint.tv/img/scm/partner-logo/xumo_logo.png",
    amagi: "http://dashboard.matchpoint.tv/img/scm/partner-logo/amagi_logo.png",
  },

  months: {
    jan: "01",
    feb: "02",
    mar: "03",
    apr: "04",
    may: "05",
    jun: "06",
    jul: "07",
    aug: "08",
    sep: "09",
    oct: "10",
    nov: "11",
    dec: "12",
  },
  sitekey: {
    key: "6LfRuiEpAAAAAMOnKMMC1ZwlDyovr3FRS0rg3iQA",
  },
  V2sitekey: {
    key: "6LfWnmkeAAAAAMWXZGH9C4J8uWyi9R7qpnoIRxPJ",
  },
  toolTipText: {
    revenue: "Total revenue generated",
    revenue_per_user: "Revenue generated per user",
    revenue_per_view: "Revenue generated per view",
    revenue_per_view_minute: "Revenue generated per view time (in min)",
    revenue_per_transaction:"Total revenue divided by total sales",
    transactions:"№ of unit sales",
    reported_revenue:"Revenue collected as per currency",
    gross_revenue:"Total gross revenue",
    estimated_revenue:"Total revenue realised",
    ad_impressions: "A metric used to quantify the number of times an advertisement is displayed",
    ad_revenue: "Income earned from displaying advertisements",
    ad_bid_wons: "Number of auctions the ad tag won after bidding",
    ad_bid_auctions: "Number of auctions the ad tag has bid on",
    ad_render_rate: "Pod Time Returned / Pod Time Requested",
    ad_fill_rate: "Pod Time Filled / Pod Time Returned",
    ad_cpm: "Average cost of one thousand ad impressions",
    ad_frequency: " Total view time divided by ad impressions",
    ad_pod_slot_requests: "A request for multiple ad slots within a single ad break",
    ad_pod_time_requested: "№ of seconds that are requested in an ad pod",
    ad_pod_time_filled: "№ of seconds that an actual ad was displayed to the user",
    ad_pod_time_returned: "№ of seconds returned with ad slots by the ad server",
    viewtime: "Number of mins viewed ( For Tubi 4 cents generated per min viewed)",
    views: "№. of View Count ( For Tubi every 10 min view counted as 1 view count)",
    users: "Reported number of users ( Available for Amagi, MP and Amazon; Calculated for Xumo {Views/1.5} )",
    viewtime_per_user: "Total view time divided by total users",
    views_per_user: "Total view count divided by total users",
    minutes_per_view: "Total view time divided by total view count",
    gb_streamed: "Mins viewed * 0.61 * .0179",
    total_cost: "CDN Cost + Delivery Cost + Music Cost",
    cost_delivery: ".001 * Total views",
    music_cost: "0.02 * Revenue",
    cost_gb_streamed: "0.03 * GBs streamed",    
  },
  QuarterlyToolTipText: {
    estimated_revenue:"Total Revenue to be realised",
    revenue_per_user: "Estimated Revenue per User",
    revenue_per_view: "Estimated Revenue per View Count",
    revenue_per_view_minute: "Estimated Revenue per View minute",
  },
  DigitalToolTipText: {
    gross_revenue:"Gross revenue earned",
    revenue: "Recorded revenue net of all adjustments",
    revenue_per_transaction:"Net Revenue per transaction",
  },
  ConsolidatedToolTipText: {
    gross_revenue:"Gross revenue earned",
    revenue: "Recorded revenue net of all adjustments",
    revenue_per_transaction:"Net Revenue per transaction",
  },
  OTTDailyTooltipText: {
    revenue: "Ad Revenue + Sub Revenue",
    views: "№ views",
    users: "№ users",
    viewtime: "№ minutes viewed",
    ad_revenue: "Income earned from displaying advertisements",
    ad_cost: "Cost incurred from displaying advertisements",
    ad_impressions: "№ times an advertisement is displayed",
    ad_pod_time_filled: "№ seconds of ad pods which is filled with an ad",
    ad_pod_time_requested: "№ seconds of ad pods which is requested of the server",
    ad_pod_time_returned: "№ seconds of ad pods which is returned by the server",
    ad_pod_slots_returned: "№ pod slots which is returned by the server",
    ad_pod_slot_requests: "№ pod slots which is requested of the server",
    ad_opportunities:"",
    ad_total_requests:"",
    ad_frequency:"",
    ad_rpm: "Revenue / Impressions * 1000",
    ad_cpm: "Cost / Impressions * 1000",
    ad_render_rate: "Pod Time Returned / Pod Time Requested",
    ad_fill_rate: "Pod Time Filled / Pod Time Returned",
    sub_revenue: "Income earned from subscriptions",
    sub_new_paid_users: "№ new users who have subscribed",
    sub_active_paid_users: "Total № users who have subscribed",
    sub_cancelled_users: "№ users who have cancelled",
    sub_new_free_trial_users: "№ users who have gotten a free trial",
    sub_upgrades: "№ users who have upgraded to a higher value plan",
    sub_downgrades: "№ users who have downgraded to a lower value plan",
    sub_free_trial_converted_users: "№ users who have taken a sub after receeing a free trial",
    sub_voluntary_cancellations: "№ users who have cancelled their sub voluntarily",
    sub_involuntary_cancellations: "№ users who have cancelled their sub involuntarily",
    sub_total_users: "Total № users in the system",
    sub_trial_users: "Total № users who have gotten a free trial",
    sub_churn_rate: "Cancelled Users / Active Paid Users",
    sub_revenue_per_user: "Revenue / Active Paid Users",    
    sub_installed_devices: "Total № installed devices",
  },
  quarterlyDoughnutColor: ["#B2DFDB","#80CBC4","#4DB6AC","#00897B","#00695C"],
  yearlyDoughnutColor: ["#D7CCC8","#BCAAA4","#A1887F","#8D6E63"],
  reportCategoryOptions : [
    { value: 'Actual Revenue Report', label: "Actual Revenue Report" },
    { value: 'Daily Revenue Report', label: "Actual Revenue Report for Daily" },
    { value: 'Licensor Report', label: "Licensor Report" },
    { value: 'Estimated Revenue Report', label: "Estimated Revenue Report" },
    { value: 'Supply Ad-Ops Report', label: "Supply Ad-Ops Report" },
    { value: 'Demand Ad-Ops Report', label: "Demand Ad-Ops Report" },
    { value: 'Subscriptions Report', label: "Subscriptions Report" },
    { value: 'New Subscriptions Report', label: "New Subscriptions Report" },
    { value: 'Business Model Estimated Report', label: "Business Model Estimated Report" },
    { value: 'Business Model Actual Report', label: "Business Model Actual Report" },
    { value: 'Ad Hoc Report', label: "Ad Hoc Report" },
    { value: 'Monthly Channel Report', label: "Monthly Channel Report" },
    { value: 'Platform Channel Report', label: "Platform Channel Report" },
    { value: 'Losing Rights Report', label: "Rights Expiration and Performance Report" },
  ],
  sameRangeChartType: [
    ['ad_impressions','pod_slot_requests', 'ad_bid_auctions', 'ad_bid_wons'],
    ['pod_time_filled', 'pod_time_requested']
  ],  
  viewerTypeForDigitalDaily : [
    {
      label: "EST",
      value: "est",
    },
    {
      label: "iVOD",
      value: "ivod",
    },
    {
      label: "SVOD",
      value: "svod",
    },
    {
      label: "AVOD",
      value: "avod",
    },
  ],
  distributionMediumOptions : [
    { value: "ott", label: "OTT" },
    { value: "digital", label: "Digital" },
  ],
  distributionMediumDailyOptions : [
    { value: "ott", label: "OTT" },
  ],
  frequencyOptions : [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "quarterly", label: "Quarterly" },
  ],
  graphValuesWithDoller:['revenue', 'revenue_per_user', 'revenue_per_view', 'revenue_per_view_minute', 'revenue_per_transaction', 'ad_revenue', 
  'total_cost', 'cost_gb_streamed', 'cost_delivery', 'music_cost', 'estimated_revenue', 'gross_revenue','ad_rpm'],
  reportTimeParameters:[
    { value: "Today", label: "Today" },
    { value: "Yesterday", label: "Yesterday" },
    { value: "Last 24 Hours", label: "Last 24 Hours" },
    { value: "Last 72 Hours", label: "Last 72 Hours" },
    { value: "Last 7 Days", label: "Last 7 Days" },
    { value: "Month to Date", label: "Month to Date" },
    { value: "Last 30 Days", label: "Last 30 Days" },
    { value: "Last Month", label: "Last Month" },
    { value: "Quarter to Date", label: "Quarter to Date" },
    { value: "All Time", label: "All Time" }
  ]
};
